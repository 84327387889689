import Vue from 'vue'
import Vuex from 'vuex'
import {get} from 'lodash'
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    yearSelection: "2020",
    loanYearSelection:"2020",
    variableSelection: "Average Age",
    selectedLoanVariable: "Borrow Amount",
    selectedLoanType: "All",
  },
  mutations: {
    setSelectedYear(state, data){
      state.yearSelection = data;
    },
    setSelectedLoanYear(state, data){
      state.loanYearSelection = data;
    },
    setSelectedVariable(state, data){
      state.variableSelection = data;
    },
    setSelectedLoanVariable(state, data){
      state.selectedLoanVariable = data;
    },
    setSelectedLoanType(state, data){
      state.selectedLoanType = data;
    }
  },
  actions: {
  },
  modules: {
  },
  getters:{
    getSelectedLoanVariable(state){
      return state.selectedLoanVariable;
    },
    getLoanYearSelection(state){
      return get(state, "loanYearSelection", "2020");
    },
    getYearSelection(state){
      return get(state, "yearSelection", "2020");
    },
    getSelectedVariable(state){
      return state.variableSelection;
    },
    getSelectedLoanType(state){
      return state.selectedLoanType;
    }
  }
})
