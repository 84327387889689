

<script>
import USSmallMapConfig from "../config/us-small";

export default {
  name: "Map",
  props: {
    mapData: {
      type: Array,
    },
    dataLabel:{
      type:String
    },
    mapTitle:{
      type:String
    },
    mapType:{
      type:String
    }
  },
  data() {
    return {};
  },
  computed: {
    minDataPoint(){
        /*
        const valArr = this.mapData.map((d)=>{
            return d[1]
        })
        const minval = Math.floor(Math.min.apply(Math, valArr))
        //const minval = 90
        console.log(minval)
        console.log(Math.floor(Math.max.apply(Math, valArr)))
        */
        //console.log(this.mapType)
        switch(this.dataLabel){
            case "Borrow Amount":
              switch(this.mapType){
                case "All":
                  //592, 7682
                  return 600
                case "Single Payment Loan":
                  //252, 6924
                  return 100
                case "Installment":
                  //592, 10743
                  return 600
                case "Real-Time Installment Loans":
                  //247, 1440
                  return 250
                default:
                  return 250
              }
            case "Loan Duration":
              switch(this.mapType){
                case "All":
                  //47, 966
                  return 50
                case "Single Payment Loan":
                  //10, 722
                  return 10
                case "Installment":
                  //132, 1827
                  return 140
                case "Real-Time Installment Loans":
                  //81, 513
                  return 85
                default:
                  return 10
              }
            case "Rejection Rate":
              //return 42
              return 1
            default:
              //break
              break
        }
        const valArr = this.mapData.map((d)=>{
            return d[1]
        })
        const minval = Math.floor(Math.min.apply(Math, valArr))
        return minval
        //return minval
    },
    maxDataPoint(){
      switch(this.dataLabel){
            case "Borrow Amount":
              switch(this.mapType){
                case "All":
                  //592, 7682
                  return 7650
                case "Single Payment Loan":
                  //252, 6924
                  return 6900
                case "Installment":
                  //592, 10743
                  return 10700
                case "Real-Time Installment Loans":
                  //247, 1440
                  return 1400
                default:
                  return 10700
              }
            case "Loan Duration":
              switch(this.mapType){
                case "All":
                  //47, 966
                  return 960
                case "Single Payment Loan":
                  //10, 722
                  return 720
                case "Installment":
                  //132, 1827
                  return 1800
                case "Real-Time Installment Loans":
                  //81, 513
                  return 510
                default:
                  return 510
              }
            case "Rejection Rate":
              //return 42
              return 505
            default:
              //break
              break
        }
        const valArr = this.mapData.map((d)=>{
            return d[1]
        })
        const maxval = Math.floor(Math.max.apply(Math, valArr))
        return maxval
    },
    mapDataPoint() {
      return this.mapData;
    },
    chartOptions() {
      const vm = this;
      return {
        chart: {
          map: USSmallMapConfig,
          // borderWidth:1,
        },
        title: {
          text: vm.mapTitle,

          // ADD margin
          margin: 50,
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          floating: true,
        },
        // subtitle: {
        //   text:
        //     'Source map: <a href="http://code.highcharts.com/mapdata/custom/world.js">World, Miller projection, medium resolution</a>',
        // },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            alignTo: "spacingBox",
          },
          enableMouseWheelZoom: false
        },
        colorAxis: {
            type: 'logarithmic',
            endOnTick: false,
            startOnTick: false,
            min: vm.minDataPoint,
            max: vm.maxDataPoint,
            minColor: '#DEDDDD',
            maxColor: '#BF0B0B',
            stops: [
                [0, '#DEDDDD'],
                [0.5, '#F5A83F'],
                [1, '#BF0B0B']
            ]
        },
        plotOptions: {
          series: {
            events: {
              click: function (e) {
                return vm.clickState(e, this);
              },
            },
          },
        },
        series: [
          {
            data: vm.mapDataPoint,
            name: vm.dataLabel,
            allowPointSelect: true,
            states: {
              hover: {
                color: "#BADA55",
              },
              select: {
                color: "#a4edba",
                borderColor: "black",
                dashStyle: "shortdot",
              },
            },
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "{point.name}",
            },
          },
        ],
      };
    },
  },
  methods: {
    clickState(e, clickedObj) {
      this.$emit("onClickState", { event: e.point });
      let text;
      if (!e.point.selected) {
           text =
          "<b style='font-size:14px'>Selected Region: </b>" + "<b style='font-size:13px; color:#2F56CF'>"+
          e.point.name + "</b>"+
          "<b style='font-size:14px'>; Data: </b>" + "<b style='font-size:13px; color:#2F56CF'>"+
          clickedObj.name + "</b>" +
          "<b style='font-size:14px'>; Value: </b>" + "<b style='font-size:13px; color:#2F56CF;'>"+
          e.point.value + "</b>" + "<br> <b style='font-size:12px;'>(see region breakdown below)</b>";
        // text =
        //   "<b style='font-size:15px'>Selected Region: </b>" + "<b style='font-size:13px; color:#2F56CF'>"+
        //   e.point.name + "</b>"+
        //   "<br><b style='font-size:15px'>Data: </b>" + "<b style='font-size:13px; color:#2F56CF'>"+
        //   clickedObj.name + "</b>" +
        //   "<br><b style='font-size:15px'>Value: </b>" + "<b style='font-size:13px; color:#2F56CF'>"+
        //   e.point.value + "</b>" + "<br>"+ "(see bar chart below)";
      } else {
        text = null;
      }

      if (!clickedObj.chart.clickLabel) {
        clickedObj.chart.clickLabel = clickedObj.chart.renderer
          .label(text, 0, 0)
          .css({
            width: "500px",
            // transform:"translate(42vw, 5vhpx) translateX(-200px)",
            transform:"translate(42vw, 40px) translateX(-200px)",
            "z-index":"1",
          })
          .add();
      } else {
        clickedObj.chart.clickLabel.attr({
          text: text,
        });
      }
    },
    clearStateSelection() {
      this.$emit("clickClearRegion");
    },
  },
};
</script>
<template>
  <div class="map-container">
    <!-- <button @click="clearStateSelection">Clear Selected State</button> -->
    <highcharts
      :constructor-type="'mapChart'"
      :options="chartOptions"
      class="map"
    ></highcharts>
  </div>
</template>
<style scoped>
.popuptext1{
    font-size:16px;
}
.popuptext2{
    font-size:14px;
    color:#2F56CF;
}
.map {
  margin-left: auto;
  margin-right: auto;
  height:calc(50vw - 200px);
  /* min-height: 850px; */
  /* height:calc(100vh - 100px); */
}
.map-container{
    /* height:calc(100vh - 100px); */
}
</style>