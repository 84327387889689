<script>
export default {
  methods:{
    ifRenderContent() {
      if(screen.availWidth < 1024) return false 
      // 750
      else return true
    }
  }
}
</script>

<template>
  <div id="app">
    <div id="header" v-if="ifRenderContent()">
      <div id="header-left-corner-container">
        <img id="logo" src="./assets/iceberg.png" />
        <div id="app-name">Alternative Loan Lab (ALL)</div>
      </div>
      <div id="nav">
        <router-link to="/">Loans</router-link> |
        <router-link to="/applicants">Applicants</router-link> |       
        <router-link to="/about">Data</router-link> |
        <router-link to="/research">Research</router-link> |
        <router-link to="/team">Team</router-link>
      </div>
    </div>
    <div id="main" v-if="ifRenderContent()">
      <!-- <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
      </div> -->
      <router-view />
    </div>
    <div v-if="!ifRenderContent()">
      To achieve the optimal viewing experience on the interactive map, please access this visualization on desktop browsers.
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: row;
}
#logo {
  border-radius: 4px;
  padding: 7px;
  /* 5px */
  width: 40px;
}
#nav {
  padding: 50px;
  /* 5px */
  position: absolute;
  left: calc(35vw);
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  font-size: 18px;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#application {
  display: flex;
  flex-direction: row;
}
#main {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
#header {
  position: fixed;
  width: 100%;
  height: 45px;
  background-color: #f2f2f2;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.125);
  /* margin: -8px 0px 0px -8px; */
  margin: -8px 0px 0px -5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  /* justify-content: space-between; */
}
#header-left-corner-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#app-name {
  color: black;
  font-size: 20px;
  font-weight: 700;
  /* font-weight: 900; */
}
#main {
  display: flex;
  width: 100%;
}
.col-1 {
  width: 8%;
  padding: 5px 5px;
}
.col-2 {
  width: 16%;
}
.col-3 {
  width: 24%;
}
.col-4 {
  width: 32%;
}
.col-5 {
  width: 40%;
  padding: 5px 5px;
}
.col-6 {
  width: 48%;
  padding: 5px 5px;
}
.col-7 {
  width: 56%;
  padding: 5px 5px;
}
.col-8 {
  width: 64%;
  padding: 5px 5px;
}
.col-9 {
  width: 72%;
  padding: 5px 5px;
}
.col-10 {
  width: 80%;
  padding: 5px 5px;
}
.col-11 {
  width: 88%;
}
</style>
