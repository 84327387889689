<script>
export default {
  name: "BarChart",
  props:{
      chartData:{
          type: Array,
      },
      selectedState:{
          type:String
      },
      plotVar:{
        type:String
      },
      chartTitle:{
        type:String
      },
      getChartLegend:{
        type:String
      },
      yAxisLabel:{
        type:String
      }
  },
  data() {
    return {};
  },
  computed: {
    xAxisLabel(){
        return this.chartData.map((d)=> d.label)
    },
    dataPoint(){
        return this.chartData.map((d)=> d[this.plotVar])
    },
    chartOptions() {
      const vm=this
      return {
        chart: {
          type: "column",
        },
        title: {
          text: vm.chartTitle,
        },
        xAxis: {
          categories: vm.xAxisLabel,
          crosshair: true,
        },
        yAxis: {
          min: 0,
          title: {
            text: vm.yAxisLabel,
          },
        },
        
        series: [
          {
            data: vm.dataPoint,
            color:"#566b87",
            name:vm.getChartLegend
          },
        ],
      };
    },
  },
  watch:{}
};
</script>
<template>
  <div>
    <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>