import Vue from 'vue'
import VueRouter from 'vue-router'
import Loan from '../views/Loan.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Loan',
    component: Loan
  },
  {
    path: '/applicants',
    name: 'Applicants',
    component: () => import(/* webpackChunkName: "about" */ '../views/Applicants.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/research',
    name: 'Research',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Research.vue')
  },
  {
    path: '/team',
    name: 'Team',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Team.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
