<script>
export default {
  name: "LineChart",
  props:{
    chartData:{
        type: Array,
    },
    selectedState:{
        type:String
    },
    chartTitle:{
      type:String,
    },
    yAxisTitle:{
      type:String
    },
    dataPointLabel:{
      type:String
    },
    trendLabel:{
      type:String
    },
  },
  computed: {
    trendData(){
      return this.chartData.map((d)=>{
        return d.value_OLS;
      })
    },
    dataPoints(){
      return this.chartData.map((d)=>{
        return d.value_plot;
      })
    },
    xLabel(){
       return this.chartData.map((d)=>{
        return d.label;
      })
    },
    chartOptions() {
      const vm = this;
      return {
        chart: {
          type: "line",
        },
        title: {
          text: vm.chartTitle,
        },
        // subtitle: {
        //   text: "Source: ??",
        // },
        xAxis: {
          categories: vm.xLabel,
        },
        yAxis: {
            title: {
                text: vm.yAxisTitle
            },
            labels: {
                align: 'left',
                x: 3,
                y: 16,
                format: '{value:.,0f}'
            },
            showFirstLabel: false
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            borderWidth: 0
        },

        plotOptions: {
            line: {
            dataLabels: {
                enabled: true
            },
            enableMouseTracking: false
            }
        },
        series: [{
            name: vm.dataPointLabel,
            lineWidth: 4,
            marker: {
                radius: 4
            },
            data: vm.dataPoints
        }, {
            name: vm.trendLabel,
            data: vm.trendData
        }]
      };
    },
  },
};
</script>
<template>
    <div>
        <highcharts class="hc" :options="chartOptions" ref="chart"></highcharts>
    </div>
</template>

<style>
</style>