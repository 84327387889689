import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import HighchartsVue from "highcharts-vue";
import Highcharts from 'highcharts'
import mapInit from 'highcharts/modules/map'
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import addUsMap from './js/us-small'

NoDataToDisplay(Highcharts)
mapInit(Highcharts)
addUsMap(Highcharts)

Vue.use(HighchartsVue);
Vue.use(Highcharts)
Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

