<script>
// @ is an alias to /src
import BarChart from "@/components/BarChart.vue";
import Map from "@/components/Map.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
// import barData from "../assets/data"
import chartTypeLookup from "../assets/variable_chart_type"
import mapdataA from "../assets/loan_map"
import mapdataP from "../assets/loan_mapP"
import mapdataI from "../assets/loan_mapI"
import mapdataN from "../assets/loan_mapN"
import linedataA from "../assets/loan_plot"
import linedataP from "../assets/loan_plotP"
import linedataI from "../assets/loan_plotI"
import linedataN from "../assets/loan_plotN"
import LineChart from "@/components/LineChart.vue"

var mapdata
var linedata


export default {
  name: "Loan",
  components: {
    Map,
    LineChart,
    BarChart
  },
  data: function () {
    return {
      yearData: [
        { key: 2015, value: "2015" },
        { key: 2016, value: "2016" },
        { key: 2017, value: "2017" },
        { key: 2018, value: "2018" },
        { key: 2019, value: "2019" },
        { key: 2020, value: "2020" },
      ],
      selectedYear: "2020",
      variableData: [
        { key: "borrow_amount", value: "Borrow Amount", xLabel: "Borrow Amount ($)" },
        { key: "rejection_rate", value: "Rejection Rate", xLabel: "Rejection Rate (%)" },
        { key: "loan_duration", value: "Loan Duration", xLabel: "Loan Duration (Days)" },
      ],
      selectedVariable: "Borrow Amount",
      typeData: [
        { key: "A", value: "All"},
        { key: "P", value: "Single Payment Loan"},
        { key: "I", value: "Installment"},
        { key: "N", value: "Real-Time Installment Loans"},
      ],
      selectedType: "All",
      selectedRegion:null,
      selectedStateDisplay:null,
      selectedMapView: "state",
    };
  },
  computed: {
    ...mapState("index", ["yearSelection", "variableSelection"]),
    ...mapGetters("index", ["getLoanYearSelection", "getSelectedLoanVariable"]),
    mapTitle(){
      return "Applicant's Loan Statistics"
    },
    mapDataLabel(){
      return this.$store.getters.getSelectedLoanVariable
    },
    mapType(){
      return this.$store.getters.getSelectedLoanType
    },
    barLegend(){
      return this.variableData.find((d)=>{
          return d.value === this.$store.getters.getSelectedLoanVariable
      }).xLabel
    },
    barChartTitle(){
      let text = this.$store.getters.getSelectedLoanVariable + " Distribution in " + this.selectedStateDisplay
      return text;
    },

    lineChartTitle(){
      return "Monthly " + this.$store.getters.getSelectedLoanVariable + " in " + this.selectedStateDisplay;
    },

    mapData(){
      switch(this.$store.getters.getSelectedLoanType){
        case "All":
          mapdata = mapdataA
          break
        case "Single Payment Loan":
          mapdata = mapdataP
          break
        case "Installment":
          mapdata = mapdataI
          break
        case "Real-Time Installment Loans":
          mapdata = mapdataN
          break
        default:
          mapdata = mapdataA
      }
      const selectedVariableKey = this.variableData.find(
        (d) => d.value === this.$store.getters.getSelectedLoanVariable
      ).key;
      return mapdata.find((d)=>{
        return d.year === parseInt(this.$store.getters.getLoanYearSelection)
          && d.variable === selectedVariableKey
      }).mapData
    },
    barData() {
      switch(this.$store.getters.getSelectedLoanType){
        case "All":
          linedata = linedataA
          break
        case "Single Payment Loan":
          linedata = linedataP
          break
        case "Installment":
          linedata = linedataI
          break
        case "Real-Time Installment Loans":
          linedata = linedataN
          break
        default:
          linedata = mapdataA
      }
      const selectedVariableKey = this.variableData.find(
        (d) => d.value === this.$store.getters.getSelectedLoanVariable
      ).key;
      
      return linedata.filter((d) => {
        return (
          d.year === parseInt(this.$store.getters.getLoanYearSelection) 
           && d.variable === selectedVariableKey
           && d.state === this.selectedRegion
        );
      });
    },
    lineData(){
      switch(this.$store.getters.getSelectedLoanType){
        case "All":
          linedata = linedataA
          break
        case "Single Payment Loan":
          linedata = linedataP
          break
        case "Installment":
          linedata = linedataI
          break
        case "Real-Time Installment Loans":
          linedata = linedataN
          break
        default:
          linedata = mapdataA
      } 
      const selectedVariableKey = this.variableData.find(
        (d) => d.value === this.$store.getters.getSelectedLoanVariable
      ).key;
      
      return linedata.filter((d) => {
        return (
          d.year === parseInt(this.$store.getters.getLoanYearSelection) 
           && d.variable === selectedVariableKey
           && d.state === this.selectedRegion
        );
      });
    },
    chartTypeLookup(){
      const selectedLoanVar = this.$store.getters.getSelectedLoanVariable === null ? "Rejection Rate" : this.$store.getters.getSelectedLoanVariable;
      return chartTypeLookup.filter((d) => {
        return d.selected_var === selectedLoanVar
      })[0]["chart_type"];
    }
  },
  methods: {
    ...mapMutations("index", ["setSelectedLoanYear", "setSelectedLoanVariable"]),

    handleMapStateClick(d) {
      if(!d.event.selected)
        {this.selectedRegion = d.event['hc-key'];
        this.selectedStateDisplay = d.event.name
        }
      else{
        this.selectedRegion = null
        this.selectedStateDisplay = null;
      }
    },
    onChangeYearSelection(e) {
      this.$store.commit("setSelectedLoanYear", e);
    },
    onChangeVariableSelection(e) {
      this.$store.commit("setSelectedLoanVariable", e);
    },
    onChangeTypeSelection(e) {
      this.$store.commit("setSelectedLoanType", e);
    },
    clearRegionSelection(){
      if(this.selectedRegion !== null){
        this.selectedRegion = null;
      }
    },
    onViewChange($event, view){
      this.selectedMapView = view
    }
  },
};
</script>
<template>
  <div class="home" id="home">
    <div id="navbar">
      <div id="navRowContainer">
        <div id="navRow">
          <div class="col-10 nav-text">Select a year:</div>
          <div class="col-10">
            <el-select
              ref="yearDropdown"
              v-model="selectedYear"
              :placeholder="'Select a year'"
              @change="onChangeYearSelection"
              clearable
            >
              <el-option
                v-for="option in yearData"
                :key="option.key"
                :label="option.label"
                :value="option.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div id="navRow">
          <div class="col-10 nav-text">Select a variable:</div>
          <div class="col-10">
             <el-select
            ref="yearDropdown"
            v-model="selectedVariable"
            :placeholder="'Select a variable'"
            @change="onChangeVariableSelection"
            clearable
          >
            <el-option
              v-for="option in variableData"
              :key="option.key"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
          </div>
        </div>
        <div id="navRow" v-if="selectedVariable != 'Rejection Rate'">
          <div class="col-10 nav-text">Select a loan type:</div>
          <div class="col-10">
             <el-select
            ref="yearDropdown"
            v-model="selectedType"
            :placeholder="'Select a loan type'"
            @change="onChangeTypeSelection"
            clearable   
          >
            <el-option
              v-for="option in typeData"
              :key="option.key"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
          </div>
        </div>
      </div>
    </div>
    <div id="content">
        <div id="map-container">
            <Map 
            :mapData="mapData"
            :dataLabel="mapDataLabel"
            :mapType="mapType"
            :mapTitle="mapTitle"
            @onClickState="handleMapStateClick"
            @clickClearRegion="clearRegionSelection"></Map>
        </div>
        <div v-if="selectedRegion !== null && chartTypeLookup==='line'" id="barchart-container">
            <LineChart 
            :chartData="lineData"
            :chartTitle="lineChartTitle"
            :yAxisTitle="'Rejection Rate (%)'"
            :dataPointLabel="'Average Rate'"
            :trendLabel="'Trend'"
            ></LineChart>
        </div>
        <div v-else-if="selectedRegion !== null && selectedVariable==='Borrow Amount'" id="barchart-container"
        >
          <BarChart 
          :chartData="barData" 
          :plotVar="'value_plot'"
          :chartTitle="barChartTitle"
          :getChartLegend="barLegend"
          :plotTitle="'Average Borrow Amount'"
          :yAxisLabel="'Dollars ($)'"
          ></BarChart>
        </div>
        <div v-else-if="selectedRegion !== null && selectedVariable==='Loan Duration'" id="barchart-container">
          <BarChart  
          :chartData="barData"
          :plotVar="'value_plot'"
          :chartTitle="barChartTitle"
          :getChartLegend="barLegend"
          :plotTitle="'Average Loan Duration'"
          :yAxisLabel="'Time (Days)'"
          ></BarChart>
        </div>
    </div>
  </div>
</template>
<style scoped>
.nav-text{
  display:flex;
  justify-content: flex-start;
  font-size:16px;
  font-weight:700;
}
#home {
  display: flex;
  flex-direction: row;
}
#navbar {
  /* max-width: 400px;
  min-width:250px; */
  width:calc(15vw);
  max-width:400px;
  min-width:calc(12vw);
  /* min-width:calc(10vw); */
  /* background-color: #f2f2f2; */
  min-height: 500px;
  height:calc(100vh - 56px);
  margin-left: -8px;
}
#navRowContainer {
  display: flex;
  flex-direction: column;
  position: fixed;
  margin: 20px 8px;
}
#navRow {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  /* 10px 0px */
  width: 92%;
  /* 85% */
}
#content {
  /* width: calc(85vw - 10px); */
  width: calc(90vw - 10px);
  margin-top: 30px;
}
#barchart-container{
  /* margin-top: 20px; */
  /* margin-left: 15%; */
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  /* width: 30%; or 70%; */
}
#map-container{
  width:calc(85vw-40px);
  padding-left: calc(5vw);
  /* padding-bottom:10px; */
  /* height:calc(100vh - 100px); */
}
#view-btn-container{
  width:100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  padding:10px 0px;
}
</style>
